import React from 'react'
import Typography from '@material-ui/core/Typography'
import './noquota.scss'
import { Button } from '@material-ui/core'
import noQuota from '../../../images/no_quota.svg'

export default class NoQuotaOverlay extends React.PureComponent {
  render() {
    return (
      <div className="no-quota-overlay">
        <div className="background" />
        <Typography variant="h5">
          {this.props.noSubscription
            ? "Looks like you don't have a subscription"
            : this.props.subscriptionExpired
            ? 'Your subscription has expired'
            : 'You ran out of Quota for this billing period'}
        </Typography>
        <img src={noQuota} className="image" alt="No Quota!" />
        <Typography variant="body1" gutterBottom>
          Your uploads may not be processed.
          <br />
          <strong>
            {this.props.pausedFilesSize > 0 &&
              `You currently have ${
                this.props.pausedFilesSize
              } files paused.`}{' '}
          </strong>
          Please{' '}
          {this.props.noSubscription ? 'sign up for a ' : 'upgrade your '}
          subscription to continue curating
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            this.props.openAuthOverlay(
              undefined,
              this.props.subscriptionExpired,
              this.props.noSubscription,
              this.props.quotaMaxed
            )
          }
        >
          {this.props.noSubscription ? 'Start for Free' : 'Upgrade now'}
        </Button>
        <Button
          size="small"
          className="later-button"
          onClick={this.props.onLater}
        >
          may be later
        </Button>
      </div>
    )
  }
}
