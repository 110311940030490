import React from 'react'
import Grid from '@material-ui/core/Grid'
import { firebase, firestore } from '../../firebase'
import FtpAgents from './ftpAgents'
import Referral from './Referral'
import Quota from './Quota'
import Summary from './Summary'
import './index.scss'

class Profile extends React.Component {
  state = { user: null }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        this.userSnap = await firestore
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            this.setState({ user: { ...user, ...doc.data() } })
          })
      }
    })
  }

  componentWillUnmount() {
    if (this.userSnap) {
      this.userSnap()
    }
  }

  render() {
    const { user } = this.state
    return user ? (
      <div className="app-page profile-page">
        <Grid container spacing={16}>
          <Grid item xs={3}>
            <Summary user={user} openAuthOverlay={this.props.openAuthOverlay} />
          </Grid>
          <Grid item xs={6}>
            <Quota user={this.state.user} />
            <br />
            <Referral user={this.state.user} />
          </Grid>
          <Grid item xs={3}>
            <FtpAgents user={this.state.user} />
          </Grid>
        </Grid>
      </div>
    ) : (
      'Not Signed in'
    )
  }
}

export default Profile
