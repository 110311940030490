import React from "react";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import "./index.scss";
import { CircularProgress } from "@material-ui/core";

export default ({
  index,
  key,
  style,
  mediaData,
  itemsPerRow,
  selectedMedia,
  selectMedia,
  retryMedia,
  cardStyle,
  deleteMedia
}) => {
  const items = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, mediaData.length);
  for (let i = fromIndex; i < toIndex; i++) {
    const card = getCard(
      deleteMedia,
      retryMedia,
      mediaData,
      selectedMedia,
      selectMedia,
      cardStyle,
      i
    );
    items.push(card);
  }
  return (
    <div className="grid-row" key={key} style={style}>
      {items}
    </div>
  );
};

const getCard = (
  deleteMedia,
  retryMedia,
  mediaData,
  selectedMedia,
  selectMedia,
  cardStyle,
  i
) =>
  mediaData[i].processing ? (
    <LoadingCard key={i} fileName={mediaData[i].fileName} style={cardStyle} />
  ) : mediaData[i].error ? (
    <ErrorCard
      mediaData={mediaData[i]}
      key={i}
      retryMedia={retryMedia}
      deleteMedia={deleteMedia}
      style={cardStyle}
    />
  ) : (
    <div
      key={i}
      style={cardStyle}
      className={`card grid-item ${selectedMedia.id === mediaData[i].id &&
        "selected"}`}
    >
      <CardMedia
        className={`card-media`}
        image={mediaData[i].thumbnail}
        title="Contemplative Reptile"
        onClick={() => selectMedia(mediaData[i].id)}
      />
      <div className="item-title">
        <div className="item-file-name">{mediaData[i].fileName}</div>
        {mediaData[i].batch && (
          <CheckCircleIcon className="item-status check" />
        )}
        <Typography variant="caption" component="p">
          {mediaData[i].batch}
        </Typography>
      </div>
    </div>
  );

const LoadingCard = ({ fileName, style }) => (
  <div className="panel-effect" style={style}>
    <div className="fake-image-gutter" />
    <div className="fake-title-gutter" />
    <div className="fake-left-gutter" />
    <div className="fake-right-gutter" />
    <div className="item-file-name fake-file-name">{fileName}</div>
  </div>
);

const ErrorCard = ({ mediaData, retryMedia, deleteMedia, style }) => (
  <div className="card grid-item error" style={style}>
    <div className="card-media error">
      <ErrorIcon className={`card-media-error`} />
    </div>
    <div className="item-title">
      <div className="item-file-name">{mediaData.fileName}</div>
      <Typography variant="caption" component="div">
        There was an error processing this file
      </Typography>
    </div>
    <div className="item-title-controlls">
      <IconButton
        onClick={() => {
          deleteMedia(mediaData.id);
        }}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          retryMedia(mediaData.id);
        }}
      >
        <RefreshIcon />
      </IconButton>
    </div>
  </div>
);

export const LoadingGrid = () => (
  <div className="grid-loading">
    <CircularProgress size={50} />
    <Typography variant="h5">Fetching your files...</Typography>
  </div>
);
