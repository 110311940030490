import React from 'react'
import Typography from '@material-ui/core/Typography'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { firestore } from '../../../firebase'
import * as Timestamp from 'react-timestamp'

import BatchIcon from './BatchIcon'

class BatchHeader extends React.Component {
  state = {
    batch: {},
  }

  componentWillUnmount() {
    if (this.batchObserver) {
      this.batchObserver()
    }
  }
  componentDidMount() {
    if (this.batchObserver) {
      this.batchObserver()
    }
    // TODO: Can we call this once in parent?
    this.batchObserver = firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('batches')
      .doc(this.props.selectedBatch.id)
      .onSnapshot(docSnapshot => {
        this.setState({ batch: docSnapshot.data() })
      })
  }

  render() {
    const { mediaFiles, toggleUploadDialog, toggleCSVDialog } = this.props
    const { batch } = this.state
    return (
      mediaFiles && (
        <div className="batches-header">
          <BatchIcon mediaFiles={mediaFiles} batchid={batch.id} />
          <div className="batches-info">
            <Typography variant="h4" color="inherit">
              {batch.name}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Created <Timestamp time={(batch.updatedAt || {}).seconds} />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {mediaFiles.length} Media Files
            </Typography>
            <div className="batches-info-actions">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={toggleUploadDialog}
                disabled={
                  batch.uploadStatus === 'uploading' ||
                  batch.uploadStatus === 'complete'
                }
              >
                {batch.uploadStatus === 'complete' ? (
                  <span className="upload-button">
                    <CheckCircleIcon className="button-icon" />
                    Upload Complete
                  </span>
                ) : batch.uploadStatus === 'uploading' ? (
                  <span className="upload-button">
                    <CircularProgress className="button-icon" size={20} />
                    Uploading..
                  </span>
                ) : (
                  <span className="upload-button">
                    <CloudUploadIcon className="button-icon" />
                    Upload to agencies
                  </span>
                )}
              </Button>

              <Button size="small" onClick={toggleCSVDialog}>
                <InsertDriveFileIcon className="button-icon" />
                Download CSV
              </Button>
              {/* <Button size="small">
              <ShareIcon className="button-icon" /> Share
            </Button>
            <Button size="small">
              <MoreVertIcon />
            </Button> */}
            </div>
          </div>
        </div>
      )
    )
  }
}

export default BatchHeader
