import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { firestore } from '../../../firebase'
import { Bar } from 'react-chartjs-2'
import { Grid, Button, IconButton } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone'
import SocialShare from '../../SocialShare'
import './index.scss'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

class Referral extends React.Component {
  constructor(props) {
    super(props)
    const d = new Date()
    const graphData = {}
    for (let index = 0; index < 12; index++) {
      graphData[`${months[d.getMonth()]}-${d.getFullYear()}`] = 0
      d.setMonth(d.getMonth() - 1)
    }
    this.state = {
      user: {},
      graphData,
    }
  }

  async componentDidMount() {
    const { graphData } = this.state
    let referalLink
    const refSnap = await firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('referrals')
      .get()
    refSnap.forEach(doc => {
      const data = doc.data()
      const date = new Date(Date(data.createdAt.seconds))
      let key = `${months[date.getMonth()]}-${date.getFullYear()}`
      if (graphData[key]) {
        graphData[key] += parseFloat((data.amount / 100).toFixed(2))
      } else {
        graphData[key] = parseFloat((data.amount / 100).toFixed(2))
      }
    })

    const userDoc = await firestore
      .collection('users')
      .doc(this.props.user.uid)
      .get()
    const userData = userDoc.data()
    if (userData.refToken) {
      referalLink = `www.uploadmystock.com/?ref=${userData.refToken}`
    }
    this.setState({ graphData, referalLink })
  }

  render() {
    const { graphData, referalLink, copied } = this.state
    const dataKeys = Object.keys(graphData).reverse()
    const colors = dataKeys.map(() => {
      return 'rgba(75, 192, 192, 1)'
    })
    const values = dataKeys.map(key => graphData[key])

    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Referral Credits
        </Typography>

        <Paper className="referral-container">
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom>
                Current earnings: ${this.earnedSofar || '0.00'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom>
                Balance:
              </Typography>
            </Grid>
          </Grid>
          {referalLink && <SocialShare url={referalLink} />}
          <div className="referal-link-wrapper">
            <Typography variant="body2" className="referal-link">
              {referalLink}
            </Typography>
            <CopyToClipboard
              text={referalLink}
              onCopy={() => this.setState({ copied: true })}
            >
              <IconButton color="primary" aria-label="Add to shopping cart">
                <FileCopyTwoTone />
              </IconButton>
            </CopyToClipboard>
            {copied && (
              <Typography className="referal-link-copied" variant="caption">
                Copied!
              </Typography>
            )}
          </div>
          <Bar
            data={{
              labels: dataKeys,
              datasets: [
                {
                  label: 'Earnings',
                  data: values,
                  backgroundColor: colors,
                },
              ],
            }}
            height={70}
            options={{
              legend: {
                display: false,
              },
            }}
          />
          <Typography variant="body1" gutterBottom>
            <Button color="primary" className="payout-button" disabled>
              Transfer to your bank
            </Button>
          </Typography>
          <Typography variant="body1" gutterBottom className="payout-text">
            We support{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.paypal.com/"
            >
              Paypal
            </a>{' '}
            and direct bank transfers. Although credits are earned in USD we can
            transfer to any currency listed here:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://transferwise.com/payouts/"
            >
              Transferwise Payouts
            </a>{' '}
            The minimum for a transfer is $50.00 USD.
          </Typography>
        </Paper>
      </div>
    )
  }
}

export default Referral
