import React from 'react'

import axios from 'axios'
import { Typography, Button, CircularProgress, Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import './index.scss'
import { PROJECT_ID } from '../../../firebase'

const keywordMap = {
  jja: {
    title: 'Popular Nouns',
    subtitle: 'Nouns associated with the keyword. Eg: cuisine → culinary art',
  },
  jjb: {
    title: 'Popular Adjectives',
    subtitle: 'Common words that describe the noun. Beach → sandy',
  },
  syn: {
    title: 'Synonyms',
    subtitle: 'Words with a similar meaning to the keyword.',
  },
  trg: {
    title: 'Trigger Words ',
    subtitle: 'Words that are triggered or associated with the keyword',
  },
  spc: {
    title: 'Hypernyms',
    subtitle:
      'Broad categories into which the key word would fall into. Eg: spoon → cutlery',
  },
  gen: {
    title: 'Direct Hyponyms',
    subtitle:
      'Specific categories the tag word will fall into. Eg: boat →  gondola',
  },
}

class KeywordHelper extends React.Component {
  state = {
    loading: false,
    keywords: {},
    usedKeywords: {},
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ loading: true, keywords: {} })
      this.fetchKeywords()
    }
  }
  componentDidMount() {
    this.setState({ loading: true, keywords: {} })
    this.fetchKeywords()
  }

  fetchKeywords = async () => {
    const resp = await axios.get(
      `https://us-central1-${PROJECT_ID}.cloudfunctions.net/getRelatedTags?q=${
        this.props.value
      }`
    )
    this.setState({ loading: false, keywords: resp.data })
  }

  useKeyword(word, tagKey) {
    const usedKeywords = this.state.usedKeywords
    usedKeywords[tagKey] = true
    this.setState({ usedKeywords })
    this.props.addKeyword({ keyword: word })
  }

  render() {
    const { value, onClose } = this.props
    const { loading, keywords, usedKeywords } = this.state
    return (
      <div className="side-panel keyword-helper">
        <div className="header">
          <div>
            <Typography color="textSecondary" variant="overline">
              Keyword Helper
            </Typography>
            <Typography variant="h5" gutterBottom>
              {value}
            </Typography>
          </div>
          <div className="spacer" />
          <Button variant="outlined" color="primary" onClick={onClose}>
            CLOSE
          </Button>
        </div>
        {loading ? (
          <div className="loader-wrapper">
            <CircularProgress />
          </div>
        ) : (
          <div className="keyword-helper-content ">
            {['jja', 'jjb', 'syn', 'trg', 'spc', 'gen'].map(key => {
              return (
                <div key={key}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    gutterBottom
                  >
                    {keywordMap[key].title}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    gutterBottom
                  >
                    {keywordMap[key].subtitle}
                  </Typography>
                  {keywords[key] && keywords[key].length === 0 && (
                    <Typography gutterBottom>None found</Typography>
                  )}
                  {keywords[key] &&
                    keywords[key].map(keyword => {
                      const tagKey = `${key}_${keyword.word}`
                      return (
                        <Chip
                          key={tagKey}
                          label={keyword.word}
                          className={`keyword ${
                            usedKeywords[tagKey] ? 'used' : ''
                          }`}
                          onClick={() => this.useKeyword(keyword.word, tagKey)}
                          onDelete={() => this.useKeyword(keyword.word, tagKey)}
                          deleteIcon={<AddIcon />}
                        />
                      )
                    })}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default KeywordHelper
