import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { firestore, firebase, PROJECT_ID } from '../../firebase'
import axios from 'axios'
import './index.scss'
import {
  FormHelperText,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Input,
} from '@material-ui/core'

// const HOST = "http://localhost:5001/${PROJECT_ID}/us-central1";
const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`
class Profile extends React.Component {
  state = {
    agentDialog: false,
    unlockRequestMade: false,
    loading: false,
    showMasterPassword: false,
    showPassword: false,
    sucessful: true,
    unlocked: false,
    user: {},
    ftpAgents: {},
    name: '',
    host: '',
    username: '',
    masterPassword: '',
    password: '',
    ftpAgentError: '',
    loadingMessage: '',
    mp: null,
  }

  async componentDidMount() {
    this.fetchFtpAgents()
    this.userRef = firestore.collection('users').doc(this.props.user.uid)
    const docSnap = await this.userRef.get()
    this.setState({ mp: docSnap.data().mp })
  }

  handleClickOpenAgentDialog = () => {
    this.setState({ agentDialog: true })
  }

  handleResetMasterKey = async () => {
    const batch = firestore.batch()
    const batchesSnap = await this.userRef.collection('ftpAgents').get()
    batch.set(this.userRef, { mp: null }, { merge: true })
    batchesSnap.forEach(batchDoc => {
      batch.delete(batchDoc.ref)
    })
    await batch.commit()
    window.location.reload()
  }

  handleCloseAgentDialog = () => {
    this.setState({
      agentDialog: false,
      unlocked: false,
      name: '',
      host: '',
      username: '',
      password: '',
      masterPassword: '',
      ftpAgentError: '',
      loadingMessage: '',
    })
  }

  fetchFtpAgents = () => {
    firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('ftpAgents')
      .onSnapshot(snapshot => {
        const { ftpAgents } = this.state
        snapshot.forEach(doc => {
          ftpAgents[doc.id] = doc.data()
        })
        this.setState({ ftpAgents })
      })
  }

  editFtpAgent = agent => {
    this.setState({
      name: agent.name,
      host: agent.host,
      username: agent.username,
      agentDialog: true,
    })
  }

  deleteAgent = host => {
    firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('ftpAgents')
      .doc(host.replace(/\./g, ''))
      .delete()
  }

  updateNewFtpValue = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleClickShowPassword = name => () => {
    this.setState({
      [name]: !this.state[name],
    })
  }

  newFTPAgent = async e => {
    e.preventDefault()
    const { name, host, username, password, masterPassword } = this.state
    const token = await firebase.auth().currentUser.getIdToken()

    this.setState({
      loading: true,
      loadingMessage: `Verifying your credentials with ${name}.`,
    })
    const resp = await axios.post(
      `${HOST}/newFTPAgent`,
      {
        name,
        host,
        username,
        password,
        masterPassword,
      },
      { headers: { authorization: 'Bearer ' + token } }
    )
    this.setState({
      loading: false,
      unlockRequestMade: false,
      sucessful: resp.data.sucessful,
      ftpAgentError: resp.data.error,
    })
    if (resp.data.sucessful) {
      this.fetchFtpAgents()
      const docSnap = await this.userRef.get()
      this.setState({ mp: docSnap.data().mp })
      this.handleCloseAgentDialog()
    }
  }

  unlockVault = async () => {
    this.setState({
      loading: true,
      loadingMessage: 'Verifying your credentials..',
    })
    const token = await firebase.auth().currentUser.getIdToken()
    const resp = await axios.post(
      `${HOST}/unlockVault`,
      {
        masterPassword: this.state.masterPassword,
      },
      { headers: { authorization: 'Bearer ' + token } }
    )
    this.setState({
      loading: false,
      unlocked: resp.data.unlocked,
      unlockRequestMade: true,
    })
  }

  buildVault = async () => {
    this.setState({
      loading: true,
      loadingMessage: 'Building a vault with your credentials..',
    })
    const token = await firebase.auth().currentUser.getIdToken()
    const resp = await axios.post(
      `${HOST}/buildVault`,
      {
        masterPassword: this.state.masterPassword,
      },
      { headers: { authorization: 'Bearer ' + token } }
    )

    this.setState({ loading: false, unlocked: resp.data.unlocked })
  }

  render() {
    const {
      ftpAgents,
      name,
      username,
      host,
      unlocked,
      mp,
      loadingMessage,
      loading,
      unlockRequestMade,
      sucessful,
      ftpAgentError,
    } = this.state
    const agents = Object.keys(ftpAgents).map(key => {
      return ftpAgents[key]
    })
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Connected Agents
        </Typography>
        <Paper>
          <List>
            {agents.map((agent, i) => {
              return (
                <ListItem key={i}>
                  {agent.testSuccess ? (
                    <Avatar className="ftp-check-ok">
                      <CheckCircleIcon />
                    </Avatar>
                  ) : (
                    <Avatar className="ftp-check-fail">
                      <CancelIcon />
                    </Avatar>
                  )}
                  <ListItemText primary={agent.name} secondary={agent.status} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        this.deleteAgent(agent.host)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}

            <ListItem button onClick={this.handleClickOpenAgentDialog}>
              <Avatar>
                <AddIcon />
              </Avatar>
              <ListItemText primary="Add New Agent" />
            </ListItem>
            {mp && (
              <ListItem button onClick={this.handleResetMasterKey}>
                <Avatar>
                  <RefreshIcon />
                </Avatar>
                <ListItemText
                  primary="Reset Master Password"
                  secondary="This action will remove all your connected agents."
                />
              </ListItem>
            )}
          </List>
        </Paper>
        <Dialog
          open={this.state.agentDialog}
          onClose={this.handleCloseAgentDialog}
          aria-labelledby="form-dialog-title"
        >
          {unlocked ? (
            <form className="ftp-form" onSubmit={this.newFTPAgent}>
              <DialogTitle id="form-dialog-title">
                Add New FTP Agent
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" gutterBottom>
                  Learn more about account security{' '}
                  <a target="_blank" href="/faq#securePassword">
                    here
                  </a>
                  .
                </Typography>

                <FormControl fullWidth>
                  <InputLabel> Name of FTP agent </InputLabel>
                  <Input
                    className="text-field"
                    autoFocus
                    id="name"
                    type="text"
                    placeholder="Shutterstock"
                    fullWidth
                    onChange={this.updateNewFtpValue('name')}
                    value={name}
                    required
                  />
                  <br />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel> Host </InputLabel>
                  <Input
                    className="text-field"
                    id="host"
                    type="text"
                    placeholder="ftp.shutterstock.com"
                    fullWidth
                    onChange={this.updateNewFtpValue('host')}
                    value={host}
                    required
                  />
                  <br />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel> Username </InputLabel>
                  <Input
                    className="text-field"
                    id="username"
                    type="text"
                    fullWidth
                    onChange={this.updateNewFtpValue('username')}
                    value={username}
                    required
                  />
                  <br />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    required
                    id="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    onChange={this.updateNewFtpValue('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword('showPassword')}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <br />

                {!sucessful && (
                  <FormHelperText className="error">
                    {`Invalid credentails. ${ftpAgentError}. Please try
                    again`}
                  </FormHelperText>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseAgentDialog} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          ) : (
            <div className="">
              <DialogTitle id="form-dialog-title">
                {mp
                  ? 'Unlock Your Vault'
                  : 'Select a master key for your vault'}
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel htmlFor="master-password">
                    Master Password
                  </InputLabel>
                  <Input
                    required
                    id="master-password"
                    type={this.state.showMasterPassword ? 'text' : 'password'}
                    onChange={this.updateNewFtpValue('masterPassword')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword(
                            'showMasterPassword'
                          )}
                        >
                          {this.state.showMasterPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {unlockRequestMade && (
                    <FormHelperText className="error">
                      The password you provided did mot match your credentials.
                      Please correct it and try again. If needed You can reset
                      and re-add your agent cerentials in the account page
                    </FormHelperText>
                  )}
                  <FormHelperText>
                    Learn more about security{' '}
                    <a target="_blank" href="/faq#securePassword">
                      here
                    </a>
                  </FormHelperText>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseAgentDialog} color="primary">
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  onClick={mp ? this.unlockVault : this.buildVault}
                >
                  {mp ? 'Unlock' : 'Build Vault'}
                </Button>
              </DialogActions>
            </div>
          )}
          {loading && (
            <div className="ftp-dialog-loading">
              <CircularProgress />
              <Typography variant="h6" className="text">
                {loadingMessage}
              </Typography>
            </div>
          )}
        </Dialog>
      </div>
    )
  }
}

export default Profile
