import { firestore } from "../firebase";

export const deleteMedia = async (userId, mediaId) => {
  const userRef = firestore.collection("users").doc(userId);
  const mediaRef = userRef.collection("mediaFiles").doc(mediaId);

  const mediaFileDoc = await mediaRef.get();
  const mediaFile = mediaFileDoc.data();

  if (mediaFile.batchId) {
    const batchRef = userRef.collection("batches").doc(mediaFile.batchId);
    await firestore.runTransaction(transaction => {
      return transaction.get(batchRef).then(batchDoc => {
        if (!batchDoc.exists) {
          console.error("Document does not exist!");
        }
        const updatedMediaCount = (batchDoc.data().mediaCount || 1) - 1; //Or 1 is to handle previous additions
        transaction.set(
          batchRef,
          { mediaCount: updatedMediaCount },
          { merge: true }
        );
      });
    });
  }
  return mediaRef.delete();
};
