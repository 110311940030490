import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { firestore } from '../../../firebase'
import { Line } from 'react-chartjs-2'
import * as dateFormat from 'dateformat'
import './index.scss'
import { Grid, CircularProgress } from '@material-ui/core'

class Quota extends React.Component {
  state = { image: [], video: [], keys: [], subscription: {}, loading: true }

  async componentDidMount() {
    const image = [],
      video = [],
      keys = []
    const userRef = firestore.collection('users').doc(this.props.user.uid)
    const quotaSnap = await userRef.collection('quota').get()
    quotaSnap.forEach(doc => {
      const data = doc.data()
      image.push(data.image)
      video.push(data.video)
      keys.push(doc.id)
    })
    const userDoc = await userRef.get()
    const subscription = userDoc.data().subscription
    this.setState({ image, video, keys, subscription, loading: false })
  }

  render() {
    const { image, video, keys, subscription, loading } = this.state
    const starts = new Date(subscription.startsAt * 1000)
    const ends = new Date(subscription.endsAt * 1000)
    const f = 'mmm dS, yy, h:MM TT'
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Usage Quota
        </Typography>

        <Paper className="referral-container">
          {loading ? (
            <div className="loading">
              <CircularProgress />
              <Typography className="text" variant="h6">
                Fetching quota data...
              </Typography>
            </div>
          ) : subscription.startsAt ? (
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Current cycle usage:
                <span className="fromTo">
                  {dateFormat(starts, f)} <strong> - </strong>
                  {dateFormat(ends, f)}
                </span>
              </Typography>

              <Grid container>
                {Object.keys(subscription)
                  .filter(
                    quotaType => quotaType === 'image' || quotaType === 'video'
                  )
                  .map(quotaType => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={quotaType}
                        className="aggrigatedQuota"
                      >
                        <Typography variant="overline">{quotaType}</Typography>
                        <Typography variant="h5">
                          {subscription[quotaType].used}/
                          {subscription[quotaType].quota}
                        </Typography>
                      </Grid>
                    )
                  })}
              </Grid>
              <br />
              <Typography variant="subtitle2" gutterBottom>
                Usage overtime:
              </Typography>
              <Line
                data={{
                  labels: keys,
                  datasets: [
                    {
                      fill: false,
                      label: 'Image',
                      data: image,
                      backgroundColor: '#ff6384',
                      borderColor: '#ff6384',
                    },
                    {
                      fill: false,
                      label: 'Video',
                      borderColor: '#36a2eb',
                      data: video,
                      backgroundColor: '#36a2eb',
                    },
                  ],
                }}
                height={100}
              />
            </div>
          ) : (
            'No Subscription'
          )}
        </Paper>
      </div>
    )
  }
}

export default Quota
