import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../../components/PrivateRoute'
import AppBar from '../../components/AppBar'
import { firebase, firestore } from '../../firebase'
import PurchaseOverlay from '../../components/PurchaseOverlay'
import Workbench from '../../components/WorkBench'
import Profile from '../../components/Profile'
import './index.scss'

class App extends React.Component {
  state = {
    user: null,
    openAuthOverlay: false,
    pausedFilesQuota: {},
    pausedFilesError: {},
  }

  openAuthOverlay = (plan, subscriptionExpired, noSubscription, quotaMaxed) => {
    this.setState({
      openAuthOverlay: true,
      plan,
      subscriptionExpired,
      noSubscription,
      quotaMaxed,
    })
  }

  closeAuthOverlay = () => {
    this.setState({ openAuthOverlay: false })
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.pausedFileObserver = firestore
          .collection('users')
          .doc(user.uid)
          .collection('pausedFiles')
          .onSnapshot(querySnapshot => {
            const pausedFilesQuota = { ...this.state.pausedFilesQuota }
            const pausedFilesError = { ...this.state.pausedFilesError }
            querySnapshot.docChanges().forEach(change => {
              const data = change.doc.data()
              if (change.type === 'removed') {
                delete pausedFilesError[change.doc.id]
                delete pausedFilesQuota[change.doc.id]
              } else {
                if (data.error === 'true') {
                  pausedFilesError[change.doc.id] = data
                } else {
                  pausedFilesQuota[change.doc.id] = data
                }
              }
            })
            this.setState({
              user,
              pausedFilesQuota,
              pausedFilesError,
            })
          })
      } else {
        this.setState({ openAuthOverlay: true })
      }
    })
  }

  componentWillUnmount() {
    if (this.pausedFileObserver) {
      this.pausedFileObserver()
    }
  }

  render() {
    const {
      user,
      openAuthOverlay,
      subscriptionExpired,
      noSubscription,
      quotaMaxed,
      pausedFilesQuota,
      pausedFilesError,
    } = this.state
    const inertSubscription =
      !subscriptionExpired && !noSubscription && !quotaMaxed
    return (
      <div className="root">
        <AppBar fluid openAuthOverlay={this.openAuthOverlay} />
        <main className="app-content">
          {user ? (
            <Router>
              <PrivateRoute
                exact
                path="/app/workbench"
                user={user}
                pausedFilesQuota={Object.values(pausedFilesQuota)}
                pausedFilesError={Object.values(pausedFilesError)}
                openAuthOverlay={this.openAuthOverlay}
                component={Workbench}
              />
              <PrivateRoute
                path="/app/profile"
                user={user}
                pausedFilesQuota={Object.values(pausedFilesQuota)}
                pausedFilesError={Object.values(pausedFilesError)}
                openAuthOverlay={this.openAuthOverlay}
                component={Profile}
              />
            </Router>
          ) : (
            'LOOKING FOR LOGED IN USER'
          )}
        </main>
        <PurchaseOverlay
          monthlyPrice={'monthlyPrice'}
          open={openAuthOverlay}
          plan={this.state.plan}
          signInOnly={inertSubscription}
          handleClose={this.closeAuthOverlay}
        />
      </div>
    )
  }
}

export default App
