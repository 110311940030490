import React from 'react'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ShortIcon from '@material-ui/icons/Sort'
import ViewHeadline from '@material-ui/icons/ViewHeadline'
import CopyIcon from '@material-ui/icons/FileCopy'
import DragHandle from '@material-ui/icons/DragHandle'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ChipInput from 'material-ui-chip-input'
import CreatableSelect from 'react-select/lib/Creatable'
import DeleteIcon from '@material-ui/icons/Delete'
import KeywordHelper from './KeywordHelper'
import SimilarImages from './SimilarImages'
import CircularProgress from '@material-ui/core/CircularProgress'
import { firebase, firestore } from '../../../firebase'
import PagesIcon from '@material-ui/icons/Pages'
import './index.scss'
import { Typography, Chip, IconButton, Tooltip, Fade } from '@material-ui/core'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { deleteMedia } from '../../utils'
import choose from '../../../images/choose.svg'
const categoryOptions = [
  'animals',
  'arts_entertainment',
  'beauty_health_fashion',
  'business',
  'backgrounds_textures',
  'food',
  'drinks',
  'Industry',
  'buildings_architecture',
  'medical',
  'Nature',
  'objects_equipment',
  'people',
  'culture_religion',
  'science',
  'sport_fitness',
  'technology',
  'transportation',
  'travel',
]
class EditorModule extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelectorChange = this.handleSelectorChange.bind(this)
    this.baseState = this.state
  }

  state = {
    openKeywordHelper: false,
    openSimilarImages: false,
    preview: null,
    editorialDescription: null,
    state: null,
    city: null,
    batch: null,
    country: null,
    batchInputValue: null,
    batchOptions: null,
    fileName: '',
    description: '',
    category: '',
    multi: '',
    keywords: [],
    editorial: false,
    selected: false,
    savingData: false,
    keywordHelper: {},
    similarImages: {},
  }

  get defaultState() {
    return {
      description: '',
      category: '',
      keywords: [],
      editorial: false,
    }
  }

  componentDidMount() {
    this.fetchBatches()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.mediaData.id &&
      this.props.mediaData.id !== prevProps.mediaData.id
    ) {
      const data = this.getFormDataFromObject(this.props.mediaData)

      const mediaData = {
        description: data.description || '',
        category: data.category || '',
        keywords: data.keywords || [],
        editorial: data.editorial || false,
        editorialDescription: data.editorialDescription || '',
        city: data.city || '',
        state: data.state || '',
        country: data.country || '',
        fileName: data.fileName,
        preview: data.preview,
      }

      if (data.batch) {
        mediaData.batchInputValue = { label: data.batch, value: data.batchId }
        mediaData.batch = data.batch
        mediaData.batchId = data.batchId
      } else {
        mediaData.batchInputValue = null
        mediaData.batch = null
        mediaData.batchId = null
      }
      if (!mediaData.keywords || mediaData.keywords.length === 0) {
        const aiKeyWords = this.props.mediaData.aiKeyWords
        const keywords = []
        const dupMap = {}
        Object.keys(aiKeyWords).forEach(type => {
          aiKeyWords[type].forEach(keyword => {
            if (!dupMap[keyword]) {
              keywords.push({ type, keyword })
              dupMap[keyword] = true
            }
          })
        })
        mediaData.keywords = keywords.filter(Boolean)
      }
      this.setState({
        ...mediaData,
        selected: true,
        openKeywordHelper: false,
        openSimilarImages: false,
      })
    }
  }

  fetchBatches = () => {
    const batchOptions = []
    firestore
      .collection('users')
      .doc(this.props.userID)
      .collection('batches')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          batchOptions.push({ value: doc.id, label: doc.data().name })
        })
        this.setState({ batchOptions })
      })
  }

  getFormDataFromObject = obj => {
    const {
      description,
      category,
      keywords,
      editorial,
      editorialDescription,
      city,
      state,
      country,
      batch,
      batchId,
      fileName,
      preview,
    } = obj
    return {
      description,
      category,
      keywords,
      editorial,
      editorialDescription,
      city,
      state,
      country,
      batch,
      fileName,
      preview,
      batchId,
    }
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleSelectorChange = name => event => {
    this.setState({
      [name]: !this.state[name],
    })
  }

  handleAddKeyword = keywordStr => {
    let keywords = [...this.state.keywords]
    const newKeywords = keywordStr.split(/\s?,\s?/)
    keywords = keywords.concat(newKeywords)
    this.setState({ keywords })
  }
  handleDeleteKeyword = (keyword, index) => {
    let keywords = [...this.state.keywords]
    keywords.splice(index, 1)
    this.setState({ keywords })
  }

  saveMetaData = async e => {
    e.preventDefault()
    const { mediaData } = this.props
    if (!this.state.category || !this.state.batch) {
      this.setState({ showErrors: true })
    } else {
      this.setState({ showErrors: false, savingData: true })
      const data = this.getFormDataFromObject(this.state)
      const userRef = firestore.collection('users').doc(this.props.userID)
      const mediaRef = userRef.collection('mediaFiles').doc(mediaData.id)
      const batchRef = userRef.collection('batches').doc(data.batchId)
      data.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      const batchDoc = await batchRef.get()
      try {
        await firestore.runTransaction(transaction => {
          return transaction
            .get(mediaRef)
            .then(async mediaDoc => {
              if (!mediaDoc.exists) {
                console.error('mediaDoc document does not exist!')
              }
              const prevBatchId = mediaDoc.data().batchId
              if (prevBatchId !== data.batchId) {
                const mediaCount = batchDoc.data().mediaCount || 0
                const updatedMediaCount = mediaCount + 1
                transaction.set(
                  batchRef,
                  {
                    mediaCount: updatedMediaCount,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  },
                  { merge: true }
                )

                if (prevBatchId) {
                  const prevBatchRef = userRef
                    .collection('batches')
                    .doc(prevBatchId)
                  const prevBatch = await prevBatchRef.get()
                  if (prevBatch.exists) {
                    const prevMediaCount =
                      (prevBatch.data().mediaCount || 1) - 1
                    transaction.set(
                      prevBatchRef,
                      { mediaCount: prevMediaCount },
                      { merge: true }
                    )
                  }
                }
              }
              transaction.update(mediaRef, data)
            })
            .then(() => {
              console.log('Transaction successfully committed!')
              this.setState({ savingData: false })
            })
        })
      } catch (error) {
        this.setState({ savingData: false })
        alert('Save Failed, Please refresh the page and try again')
      }
    }
  }

  handleBatchChange = batchInputValue => {
    this.setState({
      batchInputValue,
      batch: (batchInputValue || {}).label,
      batchId: (batchInputValue || {}).value,
    })
  }

  handleCreateBatch = inputValue => {
    this.setState({ savingData: true, loaderText: 'Creating Batch...' })

    firestore
      .collection('users')
      .doc(this.props.userID)
      .collection('batches')
      .add({
        name: inputValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(batchRef => {
        const batchOptions = this.state.batchOptions
        batchOptions.push({ value: batchRef.id, label: inputValue })
        this.setState({
          batchOptions,
          batchInputValue: { value: batchRef.id, label: inputValue },
          batch: inputValue,
          batchId: batchRef.id,
          savingData: false,
          loaderText: null,
        })
      })
  }

  chipRenderer = ({ text, handleClick, handleDelete }, key) => {
    return (
      <Chip
        className={`keyword ${text.type}`}
        key={key}
        onDelete={handleDelete}
        label={text.keyword || text}
        onClick={e => this.keywordEnter(e.target, text.keyword || text)}
      />
    )
  }

  keywordEnter = (_, value) => {
    this.setState({
      openKeywordHelper: true,
      openSimilarImages: false,
      keywordHelper: {
        value,
      },
    })
  }

  closeKeywordHelper = () => {
    this.setState({
      openKeywordHelper: false,
    })
  }

  addKeyword = word => {
    const { keywords } = this.state
    keywords.push(word)
    this.setState({ keywords })
  }

  openSimilarImages = () => {
    const { visuallySimilar, webEntities } = this.props.mediaData
    this.setState({
      openSimilarImages: true,
      openKeywordHelper: false,
      similarImages: {
        visuallySimilar,
        webEntities,
      },
    })
  }

  closeSimilarImages = () => {
    this.setState({
      openSimilarImages: false,
      similarImages: {},
    })
  }

  deleteItem = async id => {
    this.setState({ savingData: true })
    await deleteMedia(this.props.userID, id)
    this.fetchBatches()
    this.setState({ ...this.baseState, savingData: false })
  }

  toTitleCase = str => {
    str = str.toLowerCase().split('_')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join('/')
  }

  onClickAway = () => {
    this.setState({
      openSimilarImages: false,
      openKeywordHelper: false,
    })
  }

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    width: '100%',
  })

  getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#c4e2ff' : 'white',
    ...draggableStyle,
  })

  onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const keywords = this.reorder(
      this.state.keywords,
      result.source.index,
      result.destination.index
    )
    this.setState({
      keywords,
    })
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  render() {
    const { mediaData } = this.props
    const {
      description,
      category,
      editorialDescription,
      state,
      city,
      keywords,
      editorial,
      batch,
      country,
      batchInputValue,
      batchOptions,
      keywordHelper,
      fileName,
      preview,
      savingData,
      similarImages,
      selected,
      loaderText,
      openKeywordHelper,
      openSimilarImages,
      sortView,
    } = this.state

    this.anchorEl =
      this.anchorEl || document.getElementsByClassName('anchorEl')[0]
    return !selected ? (
      <div className="not-selected">
        <img src={choose} alt="no images selected" />
        <Typography variant="h5" className="not-selected-text">
          Select a file to start curating.
        </Typography>
      </div>
    ) : (
      <div className="editor-module-wrapper">
        {savingData && (
          <div className="editor-loader">
            <CircularProgress />
            <Typography component="div" variant="body1">
              {loaderText ? loaderText : 'Saving Data...'}
            </Typography>
          </div>
        )}

        <div className="anchorEl" />
        <Popper
          id="keyword-popper"
          open={openKeywordHelper || openSimilarImages}
          anchorEl={this.anchorEl}
          transition
          placement="bottom"
          className="popoverHelper"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <ClickAwayListener onClickAway={this.onClickAway}>
                {openKeywordHelper ? (
                  <KeywordHelper
                    {...keywordHelper}
                    onClose={this.closeKeywordHelper}
                    addKeyword={this.addKeyword}
                  />
                ) : (
                  <SimilarImages
                    {...similarImages}
                    onClose={this.closeSimilarImages}
                    addKeyword={this.addKeyword}
                  />
                )}
              </ClickAwayListener>
            </Fade>
          )}
        </Popper>
        <form onSubmit={this.saveMetaData}>
          <div className="editor-module-form-wrapper">
            {(preview || '').match(/.mp4/) ? (
              <video width="100%" controls src={preview} type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            ) : (
              <CardMedia
                className="card-media"
                image={preview}
                title="Contemplative Reptile"
              />
            )}

            <CardContent>
              <div className="editor-header">
                <Typography variant="subtitle2">{fileName}</Typography>
                <div className="spacer" />

                <Tooltip title="Delete this file">
                  <IconButton onClick={() => this.deleteItem(mediaData.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <FormControl
                aria-describedby="batch"
                className="full-width-input"
                error={this.state.showErrors && !batch}
              >
                <CreatableSelect
                  placeholder="Name your batch."
                  isClearable
                  onCreateOption={this.handleCreateBatch}
                  onChange={this.handleBatchChange}
                  options={batchOptions}
                  value={batchInputValue}
                  // options={colourOptions}
                />
                <FormHelperText>
                  {this.state.showErrors && !batch
                    ? 'A bacth is required for every file.'
                    : `Add this file to a batch to generate CSVs as a group.`}
                </FormHelperText>
              </FormControl>

              <FormControl
                aria-describedby="desciption-text"
                className="full-width-input"
              >
                <TextField
                  id="desciption"
                  label="Description"
                  placeholder="Detailed description of the media"
                  multiline
                  fullWidth
                  required
                  margin="dense"
                  value={description}
                  onChange={this.handleInputChange('description')}
                />
                <FormHelperText>
                  {`Word Count: (${description.length}/200)`}
                </FormHelperText>
              </FormControl>

              <Tooltip title="See similar files and associated keywords used in real world applications around the web.">
                <Button
                  onClick={this.openSimilarImages}
                  variant="outlined"
                  color="primary"
                  className="similar-button"
                >
                  <PagesIcon className="left-icon" /> Similar files From the Web
                </Button>
              </Tooltip>

              <div className="keyword-actions">
                <div className="spacer" />
                <Tooltip title="sort">
                  <IconButton
                    onClick={() =>
                      this.setState({ sortView: !this.state.sortView })
                    }
                  >
                    {sortView ? <ViewHeadline /> : <ShortIcon />}
                  </IconButton>
                </Tooltip>

                <CopyToClipboard
                  text={keywords.map(obj => obj.keyword || obj).join(',')}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <Tooltip title="copy">
                    <IconButton>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </div>

              {sortView ? (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={this.getListStyle(snapshot.isDraggingOver)}
                      >
                        {keywords.map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={`_${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="draggable-list-item"
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Chip
                                  className={`keyword ${item.type}`}
                                  label={item.keyword || item}
                                />
                                <div className="spacer" />
                                <DragHandle />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="keyword-wrapper">
                  <FormControl
                    aria-describedby="desciption-text"
                    className="full-width-input"
                  >
                    <ChipInput
                      className="chip-input-form"
                      label="Keywords"
                      placeholder="Drone, Women, Kids"
                      value={keywords}
                      onAdd={this.handleAddKeyword}
                      onDelete={this.handleDeleteKeyword}
                      chipRenderer={this.chipRenderer}
                      fullWidth
                      required={keywords.length === 0}
                    />
                    <FormHelperText>
                      {`Keyword Count: ${keywords.length} (min: 8 - max: 49)`}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}

              <FormControl
                className="three-fourth-width-input"
                error={this.state.showErrors && !category}
              >
                <InputLabel htmlFor="age-helper">Category</InputLabel>
                <Select
                  value={category}
                  onChange={this.handleInputChange('category')}
                  input={<Input name="option" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categoryOptions.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {this.toTitleCase(option)}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>
                  Categories slightly differ among agencies. We’ll match your
                  selection to the agent's closest category. You can see the
                  mappings{' '}
                  <a target="_blank" href="/faq#categoryMap">
                    here
                  </a>
                  .
                </FormHelperText>
              </FormControl>

              <FormControl className="one-third-width-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editorial}
                      onChange={this.handleSelectorChange('editorial')}
                    />
                  }
                  label="Editorial"
                />
              </FormControl>
              {editorial && (
                <span>
                  <FormControl
                    aria-describedby="desciption-text"
                    className="full-width-input"
                  >
                    <TextField
                      id="desciption"
                      label="Editorial Description"
                      placeholder="Editorial caption"
                      required={editorial}
                      disabled={!editorial}
                      multiline
                      fullWidth
                      margin="dense"
                      value={editorialDescription}
                      onChange={this.handleInputChange('editorialDescription')}
                    />
                  </FormControl>

                  <FormControl
                    aria-describedby="desciption-text"
                    className="half-width-input"
                  >
                    <TextField
                      label="City"
                      placeholder="City"
                      required={editorial}
                      disabled={!editorial}
                      multiline
                      fullWidth
                      margin="dense"
                      value={city}
                      onChange={this.handleInputChange('city')}
                    />
                  </FormControl>

                  <FormControl
                    aria-describedby="desciption-text"
                    className="half-width-input"
                  >
                    <TextField
                      label="State"
                      placeholder="State"
                      required={editorial}
                      disabled={!editorial}
                      multiline
                      fullWidth
                      margin="dense"
                      value={state}
                      onChange={this.handleInputChange('state')}
                    />
                  </FormControl>

                  <FormControl
                    aria-describedby="desciption-text"
                    className="half-width-input"
                  >
                    <TextField
                      label="Country"
                      placeholder="Country"
                      required={editorial}
                      disabled={!editorial}
                      multiline
                      fullWidth
                      margin="dense"
                      value={country}
                      onChange={this.handleInputChange('country')}
                    />
                  </FormControl>
                </span>
              )}
            </CardContent>
          </div>
          <CardActions className="card-footer">
            <Button
              size="small"
              color="secondary"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </CardActions>
        </form>
      </div>
    )
  }
}

export default EditorModule
