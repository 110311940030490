import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

const columnData = [
  {
    id: "status",
    numeric: false,
    label: "Status"
  },
  {
    id: "filePreview",
    numeric: false,
    disablePadding: false,
    label: "Preview"
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File Name"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description"
  },
  {
    id: "keywordsCount",
    numeric: true,
    disablePadding: false,
    label: "Keywords Count"
  },

  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Last Edited"
  }
];

class BatchTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    const noSort = ["actions", "filePreview"];
    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={
                  noSort.includes(column.id)
                    ? false
                    : orderBy === column.id
                    ? order
                    : false
                }
              >
                {noSort.includes(column.id) ? (
                  column.label
                ) : (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}
export default BatchTableHead;
