import React from "react";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { firestore, firebase } from '../../../firebase'
import { CSVConfig } from "../../../CSVConfig_copy";
export default class CSVDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: CSVConfig
    };
  }

  statusMesages = ["Building CSV file", "Downloading... Check Download folder"];

  handleOk = () => {
    this.props.onClose();
  };

  queueCSVDownload = agent => {
    firestore
      .collection("users")
      .doc(this.props.userid)
      .collection("CSVQueue")
      .add({
        batchId: this.props.batchid,
        batchName: this.props.batchname,
        agentName: this.state.agents[agent].name,
        agentId: agent,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(ref => {
        this.updateAgentStatus(agent, 0);
        this.listenToStatus(ref.id);
      });
  };

  listenToStatus = refId => {
    const doc = firestore
      .collection("users")
      .doc(this.props.userid)
      .collection("CSVFiles")
      .doc(refId);

    this.observer = doc.onSnapshot(
      docSnapshot => {
        const data = docSnapshot.data();
        if (data) {
          this.updateAgentStatus(data.agentId, 1);
          window.open(data.downloadURL, "_self");
        }
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  updateAgentStatus(agentId, code) {
    const agents = this.state.agents;
    agents[agentId].status = code;
    this.setState({ agents });
  }

  clearListeners = () => {
    if (this.observer) {
      this.observer();
    }
  };

  render() {
    const { agents } = this.state;
    let processing = false;

    Object.keys(agents).forEach(agent => {
      processing = agents[agent].status === 0 || processing;
    });
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onExit={this.clearListeners}
        open={this.props.open}
        aria-labelledby="csv-download-dialog-title"
      >
        <DialogTitle id="csv-download-dialog-title">
          Download CSV Files
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We currently support CSV formats for the following Agents. If your
            agent is not here please make a request by contacting customer care
            and we will add it for you!
          </DialogContentText>
          <List>
            {Object.keys(agents).map(agent => {
              const pending = agents[agent].status === 0;
              const completed = agents[agent].status === 1;
              return (
                <ListItem
                  key={agent}
                  button
                  onClick={() => this.queueCSVDownload(agent)}
                  disabled={pending}
                >
                  <ListItemText
                    primary={`${agents[agent].name} ${
                      agents[agent].status
                        ? ` | ${this.statusMesages[agents[agent].status]} `
                        : ""
                    }`}
                    secondary={agent}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Download Status"
                      onClick={() => this.queueCSVDownload(agent)}
                      disabled={pending}
                    >
                      {pending ? (
                        <CircularProgress size={35} />
                      ) : completed ? (
                        <CloudDoneOutlinedIcon />
                      ) : (
                        <CloudDownloadIcon />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOk} color="primary" disabled={processing}>
            Done Downloading files
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
