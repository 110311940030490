import React from 'react'
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import { firestore, firebase, PROJECT_ID } from '../../../firebase'
import axios from 'axios'
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
  Typography,
} from '@material-ui/core'

const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`
// const HOST = "http://localhost:5001/${PROJECT_ID}/us-central1";

export default class UploadDialog extends React.Component {
  state = {
    agents: [],
    selectedAgents: {},
    loading: true,
    unlocked: false,
    unlockRequestMade: false,
    loadingMessage: '',
    masterPassword: '',
  }

  async componentDidUpdate(prevProps) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({ loading: true })
      const agents = []
      const snapshot = await firestore
        .collection('users')
        .doc(this.props.userid)
        .collection('ftpAgents')
        .get()
      snapshot.forEach(doc => {
        agents.push({ id: doc.id, ...doc.data() })
      })
      const token = await firebase.auth().currentUser.getIdToken()
      this.setState({ token, agents, loading: false, queued: false })
    }
  }

  handleClose = e => {
    const didSelectAgents = Object.keys(this.state.selectedAgents).length > 0
    this.setState({
      agents: [],
      selectedAgents: {},
      loading: false,
      unlocked: false,
      unlockRequestMade: false,
      loadingMessage: '',
      masterPassword: '',
    })
    this.props.onClose(e, didSelectAgents)
  }

  handleQueue = async () => {
    const keys = Object.keys(this.state.selectedAgents || {})

    if (keys.length > 0) {
      const agentsToUpload = keys.filter(key => {
        return this.state.selectedAgents[key]
      })
      if (agentsToUpload.length > 0) {
        this.setState({
          loading: true,
          loadingMessage: 'Queuing your uploads..',
        })
        await axios.post(
          `${HOST}/queueUpload`,
          {
            agents: agentsToUpload,
            batchId: this.props.batchid,
            masterPassword: this.state.masterPassword,
          },
          { headers: { authorization: 'Bearer ' + this.state.token } }
        )
        this.setState({
          loading: false,
          queued: true,
        })
      }
    }
  }

  handleChange = agentId => (_, value) => {
    const selectedAgents = this.state.selectedAgents
    selectedAgents[agentId] = value
    this.setState({ selectedAgents })
  }

  handleClickShowPassword = name => () => {
    this.setState({
      [name]: !this.state[name],
    })
  }

  updatePassword = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  unlockVault = async () => {
    this.setState({
      loading: true,
      loadingMessage: 'Verifying your credentials..',
    })
    const resp = await axios.post(
      `${HOST}/unlockVault`,
      {
        masterPassword: this.state.masterPassword,
      },
      { headers: { authorization: 'Bearer ' + this.state.token } }
    )
    this.setState({
      loading: false,
      unlocked: resp.data.unlocked,
      unlockRequestMade: true,
    })
  }

  render() {
    const { value, open, ...other } = this.props
    const {
      unlocked,
      loading,
      loadingMessage,
      unlockRequestMade,
      agents,
      queued,
    } = this.state
    return (
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">
          {agents.length === 0
            ? 'You have no agents configured'
            : unlocked
            ? queued
              ? 'Your uploads have been queued!'
              : 'Upload Media to Agents'
            : 'Please Enter your master password'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {agents.length === 0
              ? 'Please head over to profile page and add your agents'
              : unlocked
              ? queued
                ? 'Your files will be transfered to the agents selected. Depending on how agents handle FTP uploads, it may take a few minutes until files appear on their dashboard'
                : 'Select the Agents you want to upload the files in this batch'
              : 'This is how we secure your agent credentials. You master password is never saved in our databases'}
          </DialogContentText>

          {agents.length === 0 ? (
            <Button color="primary" href="/app/profile">
              GO TO PROFILE
            </Button>
          ) : unlocked && !queued ? (
            agents.map(agent => {
              return (
                <ListItem key={agent.id}>
                  <ListItemIcon>
                    <Checkbox
                      disabled={!agent.testSuccess}
                      onChange={this.handleChange(agent.id)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={agent.name} secondary={agent.host} />
                </ListItem>
              )
            })
          ) : !queued ? (
            <FormControl fullWidth>
              <InputLabel htmlFor="master-password">Master Password</InputLabel>
              <Input
                required
                id="master-password"
                type={this.state.showMasterPassword ? 'text' : 'password'}
                onChange={this.updatePassword('masterPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword(
                        'showMasterPassword'
                      )}
                    >
                      {this.state.showMasterPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {unlockRequestMade && (
                <FormHelperText className="error">
                  The password you provided did mot match your credentials.
                  Please correct it and try again. If needed You can reset and
                  re-add your agent cerentials in the account page
                </FormHelperText>
              )}
              <FormHelperText>
                We will ask you for the master password everytime you upload
                files to agents. This is to unlock the encryped agent password.
                Both the unlocked password and the master passowrd are never
                saved in our databases. Learn more{' '}
                <a target="_blank" href="/faq#securePassword">
                  here
                </a>
              </FormHelperText>
            </FormControl>
          ) : (
            ''
          )}
        </DialogContent>
        {agents.length !== 0 && (
          <DialogActions>
            {!queued && (
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
            )}
            {unlocked && !queued ? (
              <Button onClick={this.handleQueue} color="primary">
                Upload
              </Button>
            ) : queued ? (
              <Button onClick={this.handleClose} color="primary">
                OK
              </Button>
            ) : (
              <Button onClick={this.unlockVault} color="primary">
                Unlock
              </Button>
            )}
          </DialogActions>
        )}

        {loading && (
          <div className="dialog-loading">
            <CircularProgress />
            <Typography variant="h6" className="text">
              {loadingMessage}
            </Typography>
          </div>
        )}
      </Dialog>
    )
  }
}
