import React from 'react'
import GridRow, { LoadingGrid } from './GridRow'
import { AutoSizer, List } from 'react-virtualized'
import { firestore } from '../../../firebase'
import { debounce } from 'throttle-debounce'
import EditorModule from './EditorModule'
import NoQuotaOverlay from './NoQuotaOverlay'
import UploadButton from '../../UploadButton'
import { CircularProgress } from '@material-ui/core'
import GridHeader from './GridHeader'
import ResumeSub from '../../ResumeSub'
import imageUpload from '../../../images/undraw_image_upload.svg'
import './index.scss'

class Curation extends React.Component {
  constructor(props) {
    super(props)
    this.previousScroll = 0
    this.lastMedia = null
    this.firestoreRefs = []
    this.state = {
      paused: false,
      selectedMedia: {},
      pausedFilesSize: 0,
      mediaDataMap: {},
      loading: true,
      quotaOverlay: false,
      filter: {
        videos: true,
        images: true,
        completed: true,
      },
    }
  }

  mediaRef = filter => {
    let ref = firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('mediaFiles')
      .orderBy('createdAt', 'desc')

    let filterProps = null

    if (filter) {
      this.setState({ filter })
      filterProps = filter
    } else {
      filterProps = this.state.filter
    }
    if (!filterProps.videos) {
      ref = ref.where('mediaType', '==', 'image')
    }
    if (!filterProps.images) {
      ref = ref.where('mediaType', '==', 'video')
    }
    if (!filterProps.completed) {
      ref = ref.where('batch', '==', null)
    }
    return ref
  }

  componentDidMount() {
    const userRef = firestore.collection('users').doc(this.props.user.uid)
    this.userSnap = userRef.onSnapshot(async userDoc => {
      const user = userDoc.data()
      if (user && user.subscription && user.subscription.image) {
        const pausedFilesRef = await userRef.collection('pausedFiles').get()
        const pausedFilesSize = pausedFilesRef.size
        const subs = user.subscription
        let quotaOverlay,
          imageQuotaMaxed,
          videoQuotaMaxed,
          noSubscription,
          subscriptionExpired

        if (subs.image.used > subs.image.quota) {
          quotaOverlay = true
          imageQuotaMaxed = true
        }
        if (subs.video.used > subs.video.quota) {
          quotaOverlay = true
          videoQuotaMaxed = true
        }
        if (
          (subs.image.quota === 0 && subs.image.quota === 0) ||
          user.canceled
        ) {
          quotaOverlay = true
          noSubscription = true
        }
        if (subs.endsAt < Date.now() / 1000) {
          quotaOverlay = true
          subscriptionExpired = true
        }

        this.setState({
          paused: user.pausedMonths,
          pausedFilesSize,
          quotaOverlay,
          imageQuotaMaxed,
          videoQuotaMaxed,
          noSubscription,
          subscriptionExpired,
        })
        if (this.props.user) {
          this.fetchFirst()
        }
      }
    })
  }

  componentWillUnmount() {
    if (this.userSnap) {
      this.userSnap()
    }
    this.unMountListenders()
  }

  getCardWidth = width => {
    if (width === 0) {
      return 255
    } else if (width < 600) {
      return (width - 32) / 2
    } else if (width < 960) {
      return (width - 48) / 3
    } else if (width < 1280) {
      return (width - 80) / 5
    } else return (width - 96) / 6
  }

  getCardHeight = width => {
    return width * 0.67 + 25 + 23
  }

  unMountListenders = () => {
    this.firestoreRefs.forEach(ref => {
      ref()
    })
  }

  fetchFirst = (filter, reset) => {
    const ref = this.mediaRef(filter)
      .limit(30)
      .onSnapshot(snapshot => {
        this.updateMediaMap(snapshot, reset)
      })
    this.firestoreRefs.push(ref)
  }

  fetchNext = () => {
    this.setState({ loadingNext: true })
    const ref = this.mediaRef()
      .startAfter(this.lastMedia.data().createdAt)
      .limit(20)
      .onSnapshot(snapshot => {
        this.updateMediaMap(snapshot)
      })
    this.firestoreRefs.push(ref)
  }

  updateFilter = name => {
    const filter = { ...this.state.filter }
    filter[name] = !filter[name]

    if (name === 'images' && !filter['videos'] && !filter[name]) {
      filter['videos'] = true
    } else if (name === 'videos' && !filter['images'] && !filter[name]) {
      filter['images'] = true
    }
    this.unMountListenders()
    this.fetchFirst(filter, true)
  }

  updateMediaMap = (snapshot, reset) => {
    let mediaDataMap = {}
    if (!reset) {
      mediaDataMap = this.state.mediaDataMap
    }
    snapshot.docChanges().forEach(change => {
      const doc = change.doc
      if (change.type === 'added') {
        this.lastMedia = snapshot.docs[snapshot.docs.length - 1]
        mediaDataMap[doc.id] = { id: doc.id, ...doc.data() }
      }
      if (change.type === 'modified') {
        mediaDataMap[doc.id] = { id: doc.id, ...doc.data() }
      }
      if (change.type === 'removed') {
        delete mediaDataMap[doc.id]
      }
    })
    this.setState({ mediaDataMap, loading: false, loadingNext: false })
  }

  selectMedia = id => {
    const selectedMedia = this.state.mediaDataMap[id]
    this.setState({ selectedMedia })
  }

  onScroll = debounce(300, ({ clientHeight, scrollHeight, scrollTop }) => {
    if (
      scrollTop > this.previousScroll &&
      scrollTop >= scrollHeight - clientHeight - 200
    ) {
      this.previousScroll = scrollTop
      this.fetchNext()
    }
  })

  retryMedia = refId => {
    firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('mediaFiles')
      .doc(refId)
      .set({ retry: true }, { merge: true })
  }

  // Duplicate function in editor moduel.. consolidate it
  deleteMedia = refId => {
    const userRef = firestore.collection('users').doc(this.props.user.uid)
    const mediaRef = userRef.collection('mediaFiles').doc(refId)
    mediaRef.delete()
  }

  later = () => {
    this.setState({ quotaOverlay: false })
  }

  render() {
    const {
      mediaDataMap,
      selectedMedia,
      loading,
      loadingNext,
      filter,
      quotaOverlay,
      pausedFilesSize,
      noSubscription,
      subscriptionExpired,
      videoQuotaMaxed,
      imageQuotaMaxed,
      paused,
    } = this.state
    const mediaData = []
    Object.keys(mediaDataMap).forEach(key => {
      mediaData.push(mediaDataMap[key])
    })
    const sortedMediaData = mediaData.sort((a, b) =>
      a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
    )
    return (
      <div style={{ height: 'calc(100% - 64px)' }}>
        <div className="dashboard-grid-view">
          <div className="grid">
            {loading ? (
              <LoadingGrid />
            ) : sortedMediaData.length > 0 && !quotaOverlay && !paused ? (
              <div style={{ height: '100%' }}>
                <GridHeader filter={filter} updateFilter={this.updateFilter} />
                <div className="dashboard-grid-wrapper">
                  <AutoSizer>
                    {({ height, width }) => {
                      const cardWidth = this.getCardWidth(width)
                      const cardHeight = this.getCardHeight(cardWidth)
                      const cardStyle = {
                        width: `${cardWidth}px`,
                        height: `${cardHeight}px`,
                      }
                      const itemsPerRow = Math.floor(width / cardWidth)
                      const rowCount = Math.ceil(
                        sortedMediaData.length / itemsPerRow
                      )

                      return (
                        <List
                          className="grid"
                          width={width}
                          height={height}
                          rowCount={loading ? rowCount + 1 : rowCount}
                          rowHeight={cardHeight}
                          onScroll={this.onScroll}
                          rowRenderer={({ index, key, style }) => (
                            <GridRow
                              selectedMedia={selectedMedia}
                              selectMedia={this.selectMedia}
                              index={index}
                              key={key}
                              style={style}
                              mediaData={sortedMediaData}
                              itemsPerRow={itemsPerRow}
                              loading={loading}
                              cardStyle={cardStyle}
                              retryMedia={this.retryMedia}
                              deleteMedia={this.deleteMedia}
                            />
                          )}
                        />
                      )
                    }}
                  </AutoSizer>
                </div>
              </div>
            ) : quotaOverlay ? (
              <NoQuotaOverlay
                onLater={this.later}
                openAuthOverlay={this.props.openAuthOverlay}
                pausedFilesSize={pausedFilesSize}
                subscriptionExpired={subscriptionExpired}
                noSubscription={noSubscription}
                quotaMaxed={videoQuotaMaxed || imageQuotaMaxed}
              />
            ) : paused ? (
              <ResumeSub onResume={() => this.setState({ paused: false })} />
            ) : (
              <div style={{ minHeight: '100%' }}>
                <GridHeader filter={filter} updateFilter={this.updateFilter} />
                <div className="no-data">
                  <img src={imageUpload} alt="No Uploaded images found" />
                  <UploadButton text="Start Uploading" />
                </div>
              </div>
            )}
            {loadingNext && (
              <div className="next-loading">
                <CircularProgress size={40} className="progress" />
                Fetching more files..
              </div>
            )}
          </div>
          <div className="editor-module">
            {this.props.user && (
              <EditorModule
                mediaData={this.state.selectedMedia}
                userID={this.props.user.uid}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Curation
