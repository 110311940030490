import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { firebase, storage } from "../firebase";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";

const pluralize = require("pluralize");

class UploadButton extends React.Component {
  state = {
    uploads: {}
  };

  uploadsCompleted = () => {
    let status = true;
    Object.keys(this.state.uploads).forEach(key => {
      status = status && this.state.uploads[key].status === "completed";
    });
    return status;
  };

  postUploadCleanup = () => {
    if (this.uploadsCompleted()) {
      window.onbeforeunload = undefined;
      setTimeout(() => {
        this.setState({ snackbar: false, uploads: {} });
      }, 2000);
    }
  };

  handleChange = selectorFiles => {
    for (let i = 0; i < selectorFiles.length; i++) {
      this.uploadFiletoStorage(selectorFiles[i]);
    }
  };

  uploadFiletoStorage = file => {
    window.onbeforeunload = e => {
      const txt = "Navigating away will cancel the upload process.";
      e.returnValue = txt;
      return txt;
    };
    this.setState({ snackbar: true });
    const refId = file.name.replace(/[\W_]+/g, "");
    const filePath = `${this.props.user.uid}/${file.name}`.replace(/\s/g, "");
    const uploadTask = storage
      .ref()
      .child(filePath)
      .put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        const uploads = { ...this.state.uploads };
        uploads[refId] = {
          fileName: file.name,
          bytesTransferred: snapshot.bytesTransferred,
          totalBytes: snapshot.totalBytes,
          task: uploadTask
        };

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            uploads[refId].status = "paused";
            break;
          case firebase.storage.TaskState.RUNNING:
            uploads[refId].status = "running";
            break;
          default:
            uploads[refId].status = ""; //What should this be?
        }
        this.setState({ uploads });
      },
      error => {
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            console.log("storage/canceled");
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            alert("Some error occured");
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        const uploads = { ...this.state.uploads };
        uploads[refId].status = "completed";
        this.setState({ uploads });
        this.postUploadCleanup();
      }
    );
  };

  closeUploadSnack = () => {
    if (!this.uploadsCompleted()) {
      Object.keys(this.state.uploads).forEach(uploadKey => {
        this.state.uploads[uploadKey].task.cancel();
      });
    }
    window.onbeforeunload = undefined;
    this.setState({ snackbar: false, uploads: {} });
  };

  render() {
    let totalBytes = 0;
    let bytesTransferred = 0;
    const uploadKeys = Object.keys(this.state.uploads);
    uploadKeys.forEach(uploadKey => {
      totalBytes += this.state.uploads[uploadKey].totalBytes;
      bytesTransferred += this.state.uploads[uploadKey].bytesTransferred;
    });
    const progress = (bytesTransferred / totalBytes) * 100;
    const completed = Object.keys(this.state.uploads).filter(key => {
      return this.state.uploads[key].status === "completed";
    });
    return (
      <div className="upload-button-wrapper">
        <span>
          <input
            className="hidden-input"
            accept="image/jpeg, video/quicktime, video/mp4"
            id="flat-button-file"
            multiple
            type="file"
            onChange={e => this.handleChange(e.target.files)}
          />
          <label htmlFor="flat-button-file">
            <Button
              component="span"
              color="secondary"
              variant="contained"
              className="upload-button"
            >
              {this.props.text}
            </Button>
          </label>
        </span>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.snackbar}
          autoHideDuration={null}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
            classes: {
              root: "upload-snack-bar"
            }
          }}
        >
          <Paper elevation={10}>
            <div className="upload-snack">
              <div className="upload-snack-text">
                <Typography variant="body2">Upload progress</Typography>
                <Typography variant="caption">
                  {this.uploadsCompleted() ? (
                    "Upload Complete"
                  ) : (
                    <span>
                      Uploading {pluralize("file", uploadKeys.length, true)}
                      <br />
                      {pluralize("file", completed.length, true)} completed (
                      {Math.floor(progress)}%)
                    </span>
                  )}
                </Typography>
              </div>
              <Button
                key="undo"
                color="secondary"
                size="small"
                onClick={this.closeUploadSnack}
              >
                {this.uploadsCompleted()
                  ? "CLOSE"
                  : `STOP ${uploadKeys.length > 1 ? "All" : ""}`}
              </Button>
            </div>
            <LinearProgress
              className="progress-bar"
              variant="determinate"
              value={progress}
              style={{ color: "#757ce8" }}
            />
          </Paper>
        </Snackbar>
      </div>
    );
  }
}

export default UploadButton;
