import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import UploadDialog from "./uploadDialog";
import { firestore } from '../../../firebase'
import * as Timestamp from "react-timestamp";
import BatchTableHead from "./BatchTableHead";
import BatchTableToolBar from "./BatchTableToolBar";
import BatchHeader from "./BatchHeader";
import "./batches.scss";
import CSVDialog from "./CSVDialog";
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import RefreshIcon from "@material-ui/icons/Refresh";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { deleteMedia } from "../../utils";

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class Batches extends React.Component {
  state = {
    order: "asc",
    orderBy: "fileName",
    selected: [],
    openUploadDialog: false,
    openCSVDialog: false,
    mediaFiles: [],
    mediaFilesMap: {}
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedBatch &&
      this.props.selectedBatch.id !== prevProps.selectedBatch.id
    ) {
      this.fetchBatchFiles();
    }
  }

  componentDidMount() {
    this.fetchBatchFiles();
  }

  fetchBatchFiles = () => {
    this.mediaFilesRef = firestore
      .collection("users")
      .doc(this.props.user.uid)
      .collection("mediaFiles");
    this.mediaFilesRef
      .where("batchId", "==", this.props.selectedBatch.id)
      .onSnapshot(
        querySnapshot => {
          const mediaFilesMap = {};
          querySnapshot.forEach(doc => {
            const data = doc.data();
            mediaFilesMap[doc.id] = {
              ...data,
              id: doc.id,
              keywordsCount: data.keywords.length,
              updatedAt: data.updatedAt.seconds
            };
          });

          this.setState({ mediaFilesMap });
        },
        error => {
          console.log("Error getting documents: ", error);
        }
      );
  };

  handleRequestSort = (_, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (_, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.mediaFiles.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (_, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  toggleUploadDialog = (_, didSelectAgents) => {
    let snackbar = false;
    if (didSelectAgents) {
      snackbar = true;
    }
    this.setState({ snackbar, openUploadDialog: !this.state.openUploadDialog });
  };

  mediaRowAction = async (e, action, mediaFile) => {
    e.stopPropagation();
    const ref = firestore
      .collection("users")
      .doc(this.props.user.uid)
      .collection("mediaFiles")
      .doc(mediaFile.id);
    if (action === "delete") {
      await deleteMedia(this.props.user.uid, mediaFile.id);
    } else if (action === "remove") {
      await firestore.runTransaction(transaction => {
        return transaction
          .get(ref)
          .then(async mediaDoc => {
            if (!mediaDoc.exists) {
              console.error("Document does not exist!");
            }
            const mediaData = mediaDoc.data();
            const batchRef = firestore
              .collection("users")
              .doc(this.props.user.uid)
              .collection("batches")
              .doc(mediaData.batchId);
            const batch = await batchRef.get();
            if (batch.exists) {
              const mediaCount = (batch.data().mediaCount || 1) - 1;
              transaction.set(batchRef, { mediaCount }, { merge: true });
            }
            transaction.update(ref, { batch: null, batchId: null });
          })
          .then(() => {
            console.log("Transaction successfully committed!");
            this.setState({ savingData: false });
          });
      });
    } else if (action === "upload") {
    }
  };

  toggleCSVDialog = props => {
    this.setState({ openCSVDialog: !this.state.openCSVDialog });
  };

  status = (obj, statusText) => {
    const x = Object.keys(obj || {}).map(agent => (obj[agent] || {}).msg);
    const truthy = x.filter(a => a === statusText);
    return truthy.length === 0 ? false : truthy.reduce(Boolean);
  };

  statusErrors = obj => {
    const x = Object.keys(obj||{}).map(agent => (obj[agent] || {}).msg);
    const truthy = x.filter(
      a => ["processing", "complete", null, undefined].indexOf(a) < 0
    );
    return truthy.length === 0 ? false : truthy.reduce(Boolean);
  };

  statusText = file => {
    const agentMessagesKeys = {};
    Object.keys(file.uploads || {}).forEach(agent => {
      if (file.uploads[agent]) {
        agentMessagesKeys[agent] = {
          name: (file.uploads[agent] || {}).name,
          agent: agent,
          msg: (file.uploads[agent] || {}).msg
        };
      }
    });
    Object.keys(file.uploadErrors || {}).forEach(agent => {
      if (file.uploadErrors[agent]) {
        agentMessagesKeys[agent] = {
          name: (file.uploadErrors[agent] || {}).name,
          agent: agent,
          msg: (file.uploadErrors[agent] || {}).msg
        };
      }
    });
    return Object.keys(agentMessagesKeys).map(
      agent => agentMessagesKeys[agent]
    );
  };

  resetUpload = async (fileId, agentId) => {
    const update = {};
    update[`uploadErrors.${agentId}`] = null;
    update[`uploads.${agentId}`] = null;
    this.mediaFilesRef.doc(fileId).update(update);
    this.closeUploadStatus();
  };

  closeUploadStatus = () => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const {
      mediaFilesMap,
      order,
      orderBy,
      selected,
      anchorEl,
      selectedRow
    } = this.state;
    const mediaFiles = [];
    Object.keys(mediaFilesMap).forEach(key => {
      mediaFiles.push(mediaFilesMap[key]);
    });

    return this.props.selectedBatch ? (
      <div className="details-wrapper">
        {this.props.selectedBatch.id && (
          <BatchHeader
            mediaFiles={mediaFiles}
            user={this.props.user}
            selectedBatch={this.props.selectedBatch}
            toggleUploadDialog={this.toggleUploadDialog}
            toggleCSVDialog={this.toggleCSVDialog}
          />
        )}
        <Paper className="table-wrapper">
          <BatchTableToolBar numSelected={selected.length} />
          <div>
            <Table aria-labelledby="tableTitle">
              <BatchTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={mediaFiles.length}
              />
              <TableBody>
                {mediaFiles.sort(getSorting(order, orderBy)).map(n => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.id}
                      className="media-table-row"
                    >
                      <TableCell>
                        <Tooltip
                          title={this.statusText(n).map((status, i) => (
                            <div key={i}>
                              {status.name}: {status.msg}{" "}
                            </div>
                          ))}
                          placement="top-start"
                        >
                          {this.status(n.uploads, "processing") ? (
                            <CircularProgress size={30} />
                          ) : this.statusErrors(n.uploadErrors) ? (
                            <ErrorIcon className="failed-upload" />
                          ) : this.status(n.uploads, "complete") ? (
                            <CheckCircleIcon className="complete-upload" />
                          ) : (
                            <div>Not uploaded</div>
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <img
                          className="file-preview"
                          src={n.thumbnail}
                          alt={"Media Preview"}
                        />
                      </TableCell>
                      <TableCell>{n.fileName}</TableCell>
                      <TableCell padding="none">{n.description}</TableCell>
                      <TableCell numeric>{n.keywordsCount}</TableCell>

                      <TableCell className="row-action-cell">
                        {<Timestamp time={n.updatedAt} />}
                        <div className="row-actions">
                          <Tooltip
                            title="Reset Upload Agents"
                            placement="top-start"
                          >
                            <IconButton
                              onClick={event =>
                                this.setState({
                                  anchorEl: event.currentTarget,
                                  selectedRow: n
                                })
                              }
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Remove from Batch"
                            placement="top-start"
                          >
                            <IconButton
                              onClick={e => this.mediaRowAction(e, "remove", n)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title="Delete Media Item"
                            placement="top-start"
                          >
                            <IconButton
                              onClick={e => this.mediaRowAction(e, "delete", n)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {selectedRow && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.closeUploadStatus}
              >
                {this.statusText(selectedRow).map((status, i) => (
                  <MenuItem
                    key={i}
                    onClick={() =>
                      this.resetUpload(selectedRow.id, status.agent)
                    }
                  >
                    {status.name}{" "}
                    <Typography variant="caption"> ({status.msg})</Typography>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.snackbar}
            autoHideDuration={5000}
            onClose={() => this.setState({ snackbar: false })}
          >
            <SnackbarContent
              message="Uploads are scheduled, Time to move on to another batch!"
              action={[
                <IconButton
                  onClick={() => this.setState({ snackbar: false })}
                  color="inherit"
                  key="close"
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </Snackbar>
        </Paper>
        <UploadDialog
          batchid={this.props.selectedBatch.id}
          userid={this.props.user.uid}
          open={this.state.openUploadDialog}
          onClose={this.toggleUploadDialog}
        />
        <CSVDialog
          batchid={this.props.selectedBatch.id}
          batchname={this.props.selectedBatch.name}
          userid={this.props.user.uid}
          open={this.state.openCSVDialog}
          onClose={this.toggleCSVDialog}
        />
      </div>
    ) : (
      ""
    );
  }
}

export default Batches;
