exports.CSVConfig = {
  "ftp.contributor.adobestock.com": {
    name: "Adobe Stock",
    keyMap: {
      Category: "category",
      Filename: "fileName",
      Keywords: "keywords",
      Title: "description"
    },
    categoryMap: {
      animals: "1",
      arts_entertainment: "9",
      beauty_health_fashion: "12",
      business: "3",
      backgrounds_textures: "8",
      food: "7",
      drinks: "4",
      Industry: "10",
      buildings_architecture: "2",
      medical: "16",
      Nature: "11",
      objects_equipment: "5",
      people: "13",
      culture_religion: "15",
      science: "16",
      sport_fitness: "18",
      technology: "19",
      transportation: "20",
      travel: "21"
    }
  },
  "ftp.shutterstock.com": {
    name: "Shutterstock",
    keyMap: {
      Categories: "category",
      Filename: "fileName",
      Keywords: "keywords",
      Description: "description",
      Editorial: "editorial"
    },
    categoryMap: {
      animals: "Animals/Wildlife",
      arts_entertainment: "Arts",
      beauty_health_fashion: "Beauty/Fashion",
      business: "Business/Finance",
      backgrounds_textures: "Backgrounds/Textures",
      food: "Food",
      drinks: "Food",
      Industry: "Industrial",
      buildings_architecture: "Buildings/Landmarks",
      medical: "Healthcare/Medical",
      Nature: "Nature",
      objects_equipment: "Objects",
      people: "People",
      culture_religion: "Religion",
      science: "Science",
      sport_fitness: "Sports/Recreation",
      technology: "Technology",
      transportation: "Transportation",
      travel: "Holidays"
    }
  },
  "portal.blackbox.global": {
    name: "Black Box",
    keyMap: {
      Category: "category",
      Filename: "fileName",
      Keywords: "keywords",
      Description: "description",
      Editorial: "editorial",
      "Editorial Text": "editorialDescription",
      "Editorial City": "city",
      "Editorial State": "state",
      "Editorial Country": "country",
      "Editorial Date": "date"
    },
    categoryMap: {
      animals: "Animals",
      arts_entertainment: "Arts and Entertainment",
      beauty_health_fashion: "Beauty and Health",
      business: "Business",
      backgrounds_textures: "",
      food: "Food",
      drinks: "Drink",
      Industry: "Industry",
      buildings_architecture: "Location and Buildings",
      medical: "Medical",
      Nature: "Nature",
      objects_equipment: "Objects and Equipment",
      people: "People",
      culture_religion: "Religion",
      science: "Science",
      sport_fitness: "Sport and Fitness",
      technology: "Technology",
      transportation: "Transportation",
      travel: "Travel"
    }
  }
};

// -----SHUTERSTOCK----
// Education
// Signs/Symbols
// Vintage*
// Abstract*
// Parks/Outdoor*
// Interiors*
// Miscellaneous*
// Celebrities*

// ----ADOBE------
// 6. States of Mind
// 14. Plants and Flowers
// 17. Social
