import React from 'react'
import Curation from './Curation'
import Batches from './Batches'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import { PROJECT_ID, firebase } from '../../firebase'
import {
  Toolbar,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core'
import UploadButton from '../UploadButton'
import './index.scss'
import mobileOverlayImage from '../../images/mobile_overlay.svg'
import axios from 'axios'
const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`

const pluralize = require('pluralize')

export default class WorkBench extends React.Component {
  state = {}

  componentDidMount() {
    this.setState({
      dashboardTab: this.dashboardTab(),
    })
  }

  dashboardTab() {
    return window.location.hash.match(/submit/) ? 1 : 0
  }

  dashboardTabChange = (_, value) => {
    if (value === 0) {
      window.history.pushState(null, null, '#curate')
    } else if (value === 1) {
      window.history.pushState(null, null, '#submit')
    } else {
      window.history.pushState(null, null, '#archive')
    }
    this.setState({ dashboardTab: value })
  }

  resumePausedFiles = async () => {
    this.setState({ loadingPausedFiles: true })
    const token = await firebase.auth().currentUser.getIdToken()
    await axios.post(
      `${HOST}/resumePausedFiles`,
      { resume: true },
      { headers: { authorization: 'Bearer ' + token } }
    )
    this.setState({ loadingPausedFiles: false })
  }

  render() {
    const { dashboardTab, loadingPausedFiles } = this.state
    const {
      user,
      pausedFilesQuota,
      pausedFilesError,
      closeAuthOverlay,
      openAuthOverlay,
    } = this.props
    return (
      user && (
        <div className="app-page">
          <div className="mobile-overlay">
            <img src={mobileOverlayImage} alt="mobile not supported" />
            <Typography variant="h6">
              We are workig a mobile app. Please use the desktop version for now
            </Typography>

            <Button variant="contained" color="primary" href="/">
              home page
            </Button>
          </div>
          <Toolbar className="tool-bar">
            <Tabs
              indicatorColor="secondary"
              textColor="primary"
              value={dashboardTab || 0}
              className="dashboard-tabs"
              onChange={this.dashboardTabChange}
            >
              <Tab
                label="Work on Media"
                classes={{ selected: 'tabSelected' }}
              />
              <Tab
                label="Batches & CSV"
                classes={{ selected: 'tabSelected' }}
              />
            </Tabs>
            <div className="appspacer" />
            <UploadButton user={user} text="Upload Files" />
            <div className="appspacer" />

            {pausedFilesQuota.length > 0 ? (
              <List dense>
                <ListItem
                  onClick={() =>
                    this.props.openAuthOverlay(null, null, null, true)
                  }
                  className="quota-alert"
                  button
                >
                  <ListItemIcon>
                    <WarningIcon className="quota-alert" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${pluralize(
                      'files',
                      pausedFilesQuota.length,
                      true
                    )} paused from processing`}
                    secondary="Click here to update plan and resume them"
                  />
                </ListItem>
              </List>
            ) : (
              pausedFilesError.length > 0 && (
                <List dense>
                  <ListItem
                    onClick={this.resumePausedFiles}
                    className="quota-alert"
                    button
                  >
                    <ListItemIcon>
                      {loadingPausedFiles ? (
                        <CircularProgress />
                      ) : (
                        <WarningIcon className="quota-alert" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${pluralize(
                        'files',
                        pausedFilesError.length,
                        true
                      )} failed processing`}
                      secondary="Click here to retry"
                    />
                  </ListItem>
                </List>
              )
            )}
          </Toolbar>

          {dashboardTab === 0 ? (
            <Curation
              user={user}
              openAuthOverlay={openAuthOverlay}
              closeAuthOverlay={closeAuthOverlay}
            />
          ) : dashboardTab === 1 ? (
            <Batches
              user={user}
              openAuthOverlay={openAuthOverlay}
              closeAuthOverlay={closeAuthOverlay}
            />
          ) : (
            ''
          )}
        </div>
      )
    )
  }
}
