import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import {
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { firestore } from '../../../firebase'
import BatchDetails from './BatchDetails'
import * as Timestamp from 'react-timestamp'
import './batches.scss'
import noBatches from '../../../images/no_batches.svg'

class Batches extends React.Component {
  state = {
    batches: { Batches_With_No_Files: [], Batches_With_Files: [] },
    selectedBatch: {},
    loading: true,
  }

  batchObserver = null
  componentDidMount() {
    const Batches_With_Files = []
    const Batches_With_No_Files = []
    firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('batches')
      .orderBy('updatedAt', 'desc')
      .get()
      .then(snapShot => {
        snapShot.forEach(doc => {
          const batchData = doc.data()
          if (batchData.mediaCount && batchData.mediaCount > 0) {
            Batches_With_Files.push({ id: doc.id, ...batchData })
          } else {
            Batches_With_No_Files.push({ id: doc.id, ...batchData })
          }
        })
        this.setState({
          batches: { Batches_With_Files, Batches_With_No_Files },
          selectedBatch: Batches_With_Files[0],
          loading: false,
        })
      })
  }

  componentWillUnmount() {
    if (this.batchObserver) {
      this.batchObserver()
    }
  }

  selectBatch = batch => {
    this.setState({ selectedBatch: batch })
  }

  deleteBatch = batch => {
    firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('batches')
      .doc(batch.id)
      .delete()
      .then(() => window.location.reload())
  }

  render() {
    const { batches, selectedBatch, loading } = this.state
    return loading ? (
      <div className="no-batches-overlay">
        <CircularProgress size={70} />
        <Typography variant="h5" className="text">
          Fetching batches......
        </Typography>
      </div>
    ) : batches.Batches_With_Files.length > 0 ? (
      <div className="batches-wrapper">
        <div>
          <List
            subheader={<ListSubheader>All Batches</ListSubheader>}
            className="batches-list"
          >
            {batches.Batches_With_Files.map((item, i) => (
              <ListItem
                key={`item-withfiles-${i}`}
                button
                onClick={() => this.selectBatch(item)}
              >
                <ListItemText
                  primary={`${item.name} (${item.mediaCount || 0})`}
                  secondary={<Timestamp time={item.updatedAt.seconds} />}
                />
              </ListItem>
            ))}
          </List>
          {batches.Batches_With_No_Files.length > 0 && (
            <List
              subheader={<ListSubheader>Batchs with no Files</ListSubheader>}
              className="batches-list"
            >
              {batches.Batches_With_No_Files.map((item, i) => (
                <ListItem key={`item-nofiles-${i}`} button>
                  <ListItemText primary={item.name} secondary="No files" />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.deleteBatch(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <div className="batch-details">
          <BatchDetails selectedBatch={selectedBatch} user={this.props.user} />
        </div>
      </div>
    ) : (
      <div className="no-batches-overlay">
        <img src={noBatches} alt="No Batches Created" />
        <Typography variant="h5" className="text">
          You haven't added your files to any batches. Get started on the "Work on
          Media" tab.
        </Typography>
      </div>
    )
  }
}

export default Batches
