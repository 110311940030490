import React from "react";
import Video from "@material-ui/icons/Videocam";
import VideoOff from "@material-ui/icons/VideocamOutlined";
import CameraOff from "@material-ui/icons/CameraAltOutlined";
import Camera from "@material-ui/icons/CameraAlt";
import CompletedOff from "@material-ui/icons/CheckCircleOutline";
import Completed from "@material-ui/icons/CheckCircle";
import { Typography, Radio } from "@material-ui/core";

export default ({ filter, updateFilter }) => (
  <div className="grid-header">
    <Typography variant="h6" className='title'>Media Files</Typography>
    <Typography variant="caption" className="caption">
      | {filter.videos && filter.images && filter.completed && "All Files"}
      {!filter.completed && "Not completed"} {!filter.videos && "Image Files"}
      {!filter.images && "Video Files"}
    </Typography>
    <div className="spacer" />
    <Radio
      checked={filter.videos}
      color={filter.videos ? "primary" : "default"}
      onClick={() => updateFilter("videos")}
      icon={<VideoOff />}
      checkedIcon={<Video />}
    />
    Videos
    <Radio
      checked={filter.images}
      color={filter.images ? "primary" : "default"}
      onClick={() => updateFilter("images")}
      icon={<CameraOff />}
      checkedIcon={<Camera />}
    />
    Images
    <Radio
      checked={filter.completed}
      color={filter.completed ? "primary" : "default"}
      onClick={() => updateFilter("completed")}
      icon={<CompletedOff />}
      checkedIcon={<Completed />}
    />
    Completed
  </div>
);
