import React from 'react'
import {
  Typography,
  Button,
  Chip,
  GridList,
  GridListTile,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import './index.scss'
import './similarImages.scss'

export default class KeywordHelper extends React.Component {
  state = {
    loading: false,
    keywords: {},
    usedKeywords: {},
  }

  useKeyword(word, tagKey) {
    const usedKeywords = this.state.usedKeywords
    usedKeywords[tagKey] = true
    this.setState({ usedKeywords })
    this.props.addKeyword({ keyword: word })
  }

  render() {
    let { webEntities, visuallySimilar, onClose } = this.props
    visuallySimilar = visuallySimilar || []
    webEntities = webEntities || []
    const { usedKeywords } = this.state

    return (
      <div className="side-panel similar-images-helper">
        <div className="header">
          <div>
            <Typography color="textSecondary" variant="overline">
              SIMILAR IMAGES FROM THE WEB
            </Typography>
            <Typography variant="body2">
              We found
              <strong> {visuallySimilar.length} </strong>
              visually similar images and
              <strong> {webEntities.length} </strong>
              associated keywords
            </Typography>
          </div>
          <div className="spacer" />
          <Button variant="outlined" color="primary" onClick={onClose}>
            CLOSE
          </Button>
        </div>

        {webEntities.map(entity => {
          const tagKey = `webEntity_${entity}`
          return (
            <Chip
              key={tagKey}
              label={entity}
              className={`keyword ${usedKeywords[tagKey] ? 'used' : ''}`}
              onDelete={() => this.useKeyword(entity, tagKey)}
              onClick={() => this.useKeyword(entity, tagKey)}
              deleteIcon={<AddIcon />}
            />
          )
        })}

        <GridList cellHeight={160} cols={2} className="grid-list">
          {visuallySimilar.map((image, i) => (
            <GridListTile key={i} cols={1}>
              <img src={image.url} alt={`Visually Similar ${i}`} />
            </GridListTile>
          ))}
        </GridList>
      </div>
    )
  }
}
