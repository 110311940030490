import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CancelIcon from '@material-ui/icons/Cancel'
import RefreshIcon from '@material-ui/icons/Refresh'
import EmailIcon from '@material-ui/icons/Email'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PauseIcon from '@material-ui/icons/Pause'
import axios from 'axios'
import Currency from 'react-currency-formatter'

import { firebase, firestore, PROJECT_ID } from '../../../firebase'
import Avatar from '@material-ui/core/Avatar'
import * as titleize from 'titleize'
import * as pluralize from 'pluralize'
import {
  CardHeader,
  IconButton,
  Divider,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItemIcon,
  Grid,
  TextField,
} from '@material-ui/core'
import './index.scss'
import cancelChill from '../../../images/cancel_chill.svg'
const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`
const dateFormat = require('dateformat')

export default class Profile extends React.Component {
  state = {
    loading: true,
    cancelDialog: false,
    loadingOverlay: false,
    subscriptions: [],
  }

  async componentDidMount() {
    const subscriptions = []
    const subscriptionRef = await firestore
      .collection('users')
      .doc(this.props.user.uid)
      .collection('subscriptions')
      .get()
    subscriptionRef.forEach(sub => {
      subscriptions.push(sub.data())
    })
    const token = await firebase.auth().currentUser.getIdToken()
    this.setState({ subscriptions, loading: false, token })
  }

  pauseSub = async (months, resume) => {
    this.setState({
      loadingOverlay: true,
      loading: resume,
      loadingMsg: `Pausing your subscription for ${pluralize(
        'month',
        months,
        true
      )}`,
    })

    await axios.post(
      `${HOST}/pauseSubscription`,
      {
        months,
        resume,
      },
      { headers: { authorization: 'Bearer ' + this.state.token } }
    )
    this.setState({
      loadingOverlay: false,
      cancelDialog: false,
      loadingMsg: ``,
      loading: false,
    })
  }

  cancel = async e => {
    e.preventDefault()
    this.setState({
      loadingOverlay: true,
      loadingMsg: `Canceling your subscription...`,
    })
    await axios.post(
      `${HOST}/cancelSubscription`,
      { reason: this.state.reason },
      {
        headers: { authorization: 'Bearer ' + this.state.token },
      }
    )
    this.setState({
      loadingOverlay: true,
      cancelComplete: true,
      loadingMsg: `Your subscription is canceled and you won't be charged for the next billing cycle. Your account is good until your current month expire. Check your email for confirmation`,
    })
  }

  setReason = el => {
    this.setState({ reason: el.target.value })
  }

  price = sub => {
    const discount = sub.discount ? sub.discount.coupon.percent_off : 0
    return sub.plan.amount / (100 + discount)
  }

  render() {
    const { user } = this.props
    const {
      loading,
      subscriptions,
      loadingOverlay,
      loadingMsg,
      forceCancel,
      cancelComplete,
    } = this.state
    let pausedUntil
    if (subscriptions[0] && user.pausedMonths) {
      const now = new Date(subscriptions[0].current_period_end * 1000)
      const currentMonth = now.getMonth()
      pausedUntil = now.setMonth(currentMonth + user.pausedMonths)
    }
    return (
      user && (
        <div className="profile-summary">
          {loading && (
            <div className="summary-loading">
              <CircularProgress />
            </div>
          )}
          <Typography variant="h6" gutterBottom>
            Profile
          </Typography>

          <Paper>
            <Card>
              <CardHeader
                avatar={
                  <Avatar src={user.photoUrl}>{user.displayName[0]}</Avatar>
                }
                action={<IconButton>{/* <MoreVertIcon /> */}</IconButton>}
                title={user.displayName}
                subheader={`Member since: ${dateFormat(
                  (user.createdAt || {}).seconds * 1000,
                  'dddd, mmmm dS, yyyy'
                )}`}
              />
              <Divider />
              <CardContent>
                <Typography variant="subtitle2">Subscriptions</Typography>

                <List>
                  {subscriptions.length === 0 ? (
                    <ListItem
                      className="subscription-item"
                      button
                      onClick={() => this.props.openAuthOverlay(null, true)}
                    >
                      <ListItemText
                        primary={"You don't have a paid subscription"}
                        secondary={'Click here to start one!'}
                      />
                    </ListItem>
                  ) : user.canceled || user.cancelAtPeriodEnd ? (
                    <ListItem
                      className="subscription-item"
                      button
                      onClick={() => this.props.openAuthOverlay(null, true)}
                    >
                      <ListItemText
                        primary={
                          user.canceled
                            ? `You canceled your subscription`
                            : 'Your subscription is scheduled to be canceled'
                        }
                        secondary={'Click here to start one!'}
                      />
                    </ListItem>
                  ) : (
                    user.pausedMonths && (
                      <ListItem
                        className="subscription-item"
                        button
                        onClick={() => this.pauseSub(undefined, true)}
                      >
                        <ListItemIcon>
                          <PauseIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Paused until: ${dateFormat(
                            pausedUntil,
                            'mmmm dS, yyyy'
                          )}`}
                          secondary={'Click to resume!'}
                        />
                      </ListItem>
                    )
                  )}

                  {subscriptions.map((sub, i) => (
                    <ListItem key={i} className="subscription-item">
                      <ListItemText
                        primary={titleize(sub.plan.nickname.replace(/_/g, ' '))}
                        secondary={
                          <span>
                            <Currency quantity={this.price(sub)} />
                            /Month
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        {!user.canceled && (
                          <IconButton
                            onClick={() =>
                              this.setState({ cancelDialog: true })
                            }
                          >
                            <CancelIcon />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() => this.props.openAuthOverlay(null, true)}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>

                <Typography variant="subtitle2">Contact</Typography>
                <ListItem className="subscription-item">
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.email} secondary={'Email'} />
                </ListItem>
              </CardContent>
            </Card>
          </Paper>

          <Dialog
            onClose={() =>
              this.setState({
                loadingOverlay: false,
                loadingMsg: '',
                forceCancel: false,
                cancelComplete: false,
                cancelDialog: false,
              })
            }
            open={this.state.cancelDialog}
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              {'Cancel Subscription'}
            </DialogTitle>
            {loadingOverlay && (
              <div className="loading-overlay">
                {!cancelComplete && <CircularProgress />}
                <Typography variant="h6">{loadingMsg}</Typography>
                {cancelComplete && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="cancel-button"
                    onClick={() => this.setState({ cancelDialog: false })}
                  >
                    Close
                  </Button>
                )}
              </div>
            )}
            <DialogContent>
              <Grid container spacing={16}>
                <Grid xs={6} item>
                  <img
                    src={cancelChill}
                    className="cancel-graphic"
                    alt="Relaxing person"
                  />
                </Grid>
                <Grid xs={6} item>
                  {forceCancel ? (
                    user.cancelAtPeriodEnd ? (
                      <span>
                        <Typography variant="h4" gutterBottom>
                          Its already Canceled
                        </Typography>
                        <Typography variant="subtitle2">
                          Your account is already scheduled to be canceled by
                          the end of this billing period
                        </Typography>
                      </span>
                    ) : (
                      <form onSubmit={e => this.cancel(e)}>
                        <Typography variant="h4" gutterBottom>
                          Sorry to see you go :(
                        </Typography>
                        <TextField
                          required
                          label="Cancel Reason"
                          style={{ margin: 8 }}
                          placeholder="Reason"
                          helperText="Please provide a descriptive reason. It will help us to make this product better for content creators like you!"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={this.setReason}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="cancel-button"
                        >
                          Cancel my subscription
                        </Button>
                      </form>
                    )
                  ) : (
                    <span>
                      <Typography variant="h4" gutterBottom>
                        There is a better way!
                      </Typography>
                      <Typography gutterBottom>
                        We understand that sometimes you want to take a break.
                        However canceling your subscripton will delete all your
                        files and you may lose you rate if you signed up with a
                        promotion!
                      </Typography>
                      <Typography gutterBottom>
                        Rather consider pausing your membership for FREE! you
                        won't be charged when your membership is paused
                      </Typography>

                      {user.pausedMonths ? (
                        <Typography variant="subtitle2">
                          Looks like you are all set!. You have paused your
                          subscription until{' '}
                          {dateFormat(pausedUntil, 'dddd, mmmm dS, yyyy')}
                        </Typography>
                      ) : (
                        <div>
                          <Button
                            onClick={() => this.pauseSub(1)}
                            variant="outlined"
                            color="primary"
                            className="pause-button"
                          >
                            Pause for a month
                          </Button>
                          <Button
                            onClick={() => this.pauseSub(2)}
                            variant="outlined"
                            color="primary"
                            className="pause-button"
                          >
                            Pause for 2 months
                          </Button>
                          <Button
                            onClick={() => this.pauseSub(3)}
                            variant="outlined"
                            color="primary"
                            className="pause-button"
                          >
                            Pause for 3 months
                          </Button>
                        </div>
                      )}
                      <Button
                        size="small"
                        className="cancel-button"
                        onClick={() => this.setState({ forceCancel: true })}
                      >
                        I'll Cancel anyway
                      </Button>
                    </span>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      )
    )
  }
}
