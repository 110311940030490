import React from "react";

class BatchIcon extends React.Component {
  render() {
    return (
      <div
        className={`batchIcon batchIcon-${
          this.props.mediaFiles.length > 4 ? 4 : this.props.mediaFiles.length
        }`}
      >
        {this.props.mediaFiles.slice(0, 4).map((file, i) => {
          return (
            <div
              key={i}
              className="img"
              style={{
                backgroundImage: `url(${file.thumbnail})`
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default BatchIcon;
